import '../assets/Timeliness.css';

export default function Timeliness() {
  return (
    <div className="timeline-container">
      <div className="timeline">
        <div className="event left">
          <div className="date">23.12.01~04</div>
          <div className="content">일본여행(오사카)</div>
        </div>
        <div className="event right">
          <div className="date">24.12.28</div>
          <div className="content">호두깎이 인형 관람</div>
        </div>
        <div className="event left">
          <div className="date">24.01.12</div>
          <div className="content">일러스트 페어</div>
        </div>
        <div className="event right">
          <div className="date">24.02.25</div>
          <div className="content">첫편지</div>
        </div>
        <div className="event left">
          <div className="date">24.04.15~18</div>
          <div className="content">일본여행(도쿄)</div>
        </div>
        <div className="event right">
          <div className="date">24.05.07</div>
          <div className="content">군 입영</div>
        </div>
        <div className="event left">
          <div className="date">24.06.11</div>
          <div className="content">훈련소 수료식</div>
        </div>
        <div className="event right">
          <div className="date">24.07.19</div>
          <div className="content">300일 달성</div>
        </div>
        <div className="event left">
          <div className="date">24.08.11</div>
          <div className="content">첫 외박</div>
        </div>
        <div className="event right">
          <div className="date">24.08.27~30</div>
          <div className="content">첫휴가</div>
        </div>
        <div className="event left">
          <div className="date">24.09.20</div>
          <div className="content">1주년 기념(로카우스 호텔)</div>
        </div>
        {/* Repeat similar events */}
      </div>
    </div>
  );
}