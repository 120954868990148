import React from 'react';
import '../../assets/Quiz.css'; // 같은 CSS 파일을 공유

const Ques2 = () => {
  return (
    <div className="ques-container">
      <h1 className="ques-title">처음으로 같이 본 발레 공연은?</h1>
      <div className="button-container">
        <a href="/quiz/result/O" className="custom-button-link">
          <button className="custom-button">호두깎이 인형</button>
        </a>
        <a href="/quiz/result/X" className="custom-button-link">
          <button className="custom-button">백조의 호수</button>
        </a>
        <a href="/quiz/result/X" className="custom-button-link">
          <button className="custom-button">발레리노</button>
        </a>
      </div>
    </div>
  );
};

export default Ques2;