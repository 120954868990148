import React from 'react';
import '../../assets/Quiz.css'; // 같은 CSS 파일을 공유

const Ques1 = () => {
  return (
    <div className="ques-container">
      <h1 className="ques-title">우리가 사귀기 시작한 날은?</h1>
      <div className="button-container">
        <a href="/quiz/result/X" className="custom-button-link">
          <button className="custom-button">9월 22일</button>
        </a>
        <a href="/quiz/result/O" className="custom-button-link">
          <button className="custom-button">9월 23일</button>
        </a>
        <a href="/quiz/result/X" className="custom-button-link">
          <button className="custom-button">9월 24일</button>
        </a>
      </div>
    </div>
  );
};

export default Ques1;