import React from 'react';
import { useNavigate } from 'react-router-dom';
import '../assets/Quiz.css';

const Quiz = () => {
  const navigate = useNavigate();

  const weightedQuestions = [
    { ques: 1, weight: 6 },
    { ques: 2, weight: 6 },
    { ques: 3, weight: 6 },
    { ques: 4, weight: 6 },
    { ques: 5, weight: 3 },
    { ques: 6, weight: 3 }
  ];

  // 가중치를 기반으로 랜덤하게 질문을 선택하는 함수
  const getRandomQuestion = () => {
    const totalWeight = weightedQuestions.reduce((total, item) => total + item.weight, 0); // 가중치의 총합
    const randomNum = Math.random() * totalWeight; // 0과 totalWeight 사이의 랜덤 숫자 생성

    let weightSum = 0;
    for (let i = 0; i < weightedQuestions.length; i++) {
      weightSum += weightedQuestions[i].weight; // 현재까지의 가중치 합
      if (randomNum < weightSum) {
        return weightedQuestions[i].ques; // 가중치가 넘는 시점에서 해당 질문 반환
      }
    }
  };

  const startRandomQuiz = () => {
    const randomQues = getRandomQuestion();
    navigate(`/quiz/${randomQues}`);
  };

  return (
    <div className="quiz-container">
      <h1 className="quiz-title">1주년 기념 퀴즈</h1>
      <p className="quiz-description">어떤 문제가 나올까?</p>
      <button className="start-button" onClick={startRandomQuiz}>
        시작하기
      </button>
    </div>
  );
};

export default Quiz;