import React from 'react';
import '../../assets/Quiz.css'; // 같은 CSS 파일을 공유

const Ques6 = () => {
  return (
    <div className="ques-container">
      <h1 className="ques-title">우리가 다시 재회할 날은?</h1>
      <div className="button-container">
        <a href="/quiz/result/X" className="custom-button-link">
          <button className="custom-button">2024년</button>
        </a>
        <a href="/quiz/result/O" className="custom-button-link">
          <button className="custom-button">2025년</button>
        </a>
        <a href="/quiz/result/X" className="custom-button-link">
          <button className="custom-button">2026년</button>
        </a>
      </div>
    </div>
  );
};

export default Ques6;