import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';
import Home from './components/Home';
import Timeliness from './components/Timeliness';
import BucketList from './components/BucketList';
import Letter from './components/Letter';
import Quiz from './components/Quiz';
import Quiz1 from './components/Quiz/Quiz1';
import Quiz2 from './components/Quiz/Quiz2';
import Quiz3 from './components/Quiz/Quiz3';
import Quiz4 from './components/Quiz/Quiz4';
import Quiz5 from './components/Quiz/Quiz5';
import Quiz6 from './components/Quiz/Quiz6';
import O from './components/Quiz/result/O';
import X from './components/Quiz/result/X';
import './App.css';

function App() {
  const [authenticated, setAuthenticated] = useState(false); // 인증 상태
  const [passwordInput, setPasswordInput] = useState(''); // 비밀번호 입력값
  const correctPassword = '1주년'; // 실제 비밀번호

  useEffect(() => {
    // URL 쿼리 파라미터에서 비밀번호 확인
    const urlParams = new URLSearchParams(window.location.search);
    const passwordFromURL = urlParams.get('password');

    if (passwordFromURL === correctPassword) {
      setAuthenticated(true);
    }
  }, []);

  const handlePasswordSubmit = () => {
    if (passwordInput === correctPassword) {
      setAuthenticated(true);
    } else {
      alert('비밀번호가 틀렸습니다!');
    }
  };

  // 비밀번호가 인증되지 않은 경우 비밀번호 입력 폼 표시
  if (!authenticated) {
    return (
      <div style={{ 
        display: 'flex', 
        flexDirection: 'column', 
        justifyContent: 'center', 
        alignItems: 'center', 
        height: '100vh' 
      }}>
        <div style={{ 
          textAlign: 'center', 
          width: '300px', 
          padding: '20px', 
          border: '1px solid #ccc',
          borderRadius: '8px' 
        }}>
          <h2>비밀번호 확인</h2>
          <input
            type="password"
            value={passwordInput}
            onChange={(e) => setPasswordInput(e.target.value)}
            style={{ 
              width: '70%', 
              padding: '10px',
              borderRadius: '4px',
              border: '1px solid #ccc'
            }} 
          />
          <button onClick={handlePasswordSubmit} style={{ 
            width: '100%', 
            padding: '10px', 
            backgroundColor: '#4CAF50', 
            color: 'white', 
            border: 'none', 
            borderRadius: '4px',
            cursor: 'pointer'
          }}>Submit</button>
        </div>
      </div>
    );
  }

  // 비밀번호가 인증된 경우 기존의 App 컴포넌트 렌더링
  return (
    <Router>
      <div className="app">
        <nav className="navbar">
          <ul className="nav-links">
            <li>
              <Link to="/">Home</Link>
            </li>
            <li>
              <Link to="/timeliness">Timeliness</Link>
            </li>
            <li>
              <Link to="/bucketlist">Bucket List</Link>
            </li>
            <li>
              <Link to="/letter">Letter</Link>
            </li>
          </ul>
        </nav>

        <div className="content">
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/timeliness" element={<Timeliness />} />
            <Route path="/bucketlist" element={<BucketList />} />
            <Route path="/quiz" element={<Quiz />} />
            <Route path="/quiz/1" element={<Quiz1 />} />
            <Route path="/quiz/2" element={<Quiz2 />} />
            <Route path="/quiz/3" element={<Quiz3 />} />
            <Route path="/quiz/4" element={<Quiz4 />} />
            <Route path="/quiz/5" element={<Quiz5 />} />
            <Route path="/quiz/6" element={<Quiz6 />} />
            <Route path="/letter" element={<Letter />} />
            <Route path="/quiz/result/O" element={<O />} />
            <Route path="/quiz/result/X" element={<X />} />{/* 정확한 경로로 수정 */}
          </Routes>
        </div>
      </div>
    </Router>
  );
}

export default App;