// src/components/Home.js
import '../assets/Home.css';
import celebrity from '../assets/celebrity.jpg';
export default function Home() {
  const eventDate = new Date('2023-09-23');
  const today = new Date();
  const diffTime = Math.abs(today - eventDate);
  const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));

  return (
    <div className="home-container">
      <img src={celebrity} alt="기념사진" className="image" />
      <h1>경) 연애 1주년 달성! (축</h1>
      <p>장소 : 로카우스 나인트리 호텔</p>
      <p>D+{diffDays}</p>
    </div>
  );
}