import React from 'react';
import { useNavigate } from 'react-router-dom';
import '../../../assets/OX.css'; // O와 X 스타일 공유 가능
import inuX from '../../../assets/inuX.png'; //

const X = () => {
  const navigate = useNavigate();

  const handleReturnToQuiz = () => {
    navigate('/quiz'); // 퀴즈 탭으로 돌아가기
  };

  return (
    <div className="result-container">
      <img src={inuX} alt="오답 이미지" className="result-image" />
      {/* 이미지나 추가 설명을 여기에 넣을 수 있음 */}
      <p className="result-description">다시 한 번 생각해볼래?</p>
      <button className="return-button" onClick={handleReturnToQuiz}>
        돌아가기
      </button>
    </div>
  );
};

export default X;