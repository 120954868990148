import React from 'react';
import '../../assets/Quiz.css'; // 같은 CSS 파일을 공유

const Ques4 = () => {
  return (
    <div className="ques-container">
      <h1 className="ques-title">우리가 같이 가본적 없는 장소는?</h1>
      <div className="button-container">
        <a href="/quiz/result/X" className="custom-button-link">
          <button className="custom-button">도쿄</button>
        </a>
        <a href="/quiz/result/O" className="custom-button-link">
          <button className="custom-button">부산</button>
        </a>
        <a href="/quiz/result/X" className="custom-button-link">
          <button className="custom-button">오사카</button>
        </a>
      </div>
    </div>
  );
};

export default Ques4;