import React from 'react';
import '../../assets/Quiz.css'; // 같은 CSS 파일을 공유

const Ques5 = () => {
  return (
    <div className="ques-container">
      <h1 className="ques-title">내가 누나에게 처음으로 정기편지를 쓴 주는?</h1>
      <div className="button-container">
        <a href="/quiz/result/X" className="custom-button-link">
          <button className="custom-button">24년 6주차</button>
        </a>
        <a href="/quiz/result/O" className="custom-button-link">
          <button className="custom-button">24년 8주차</button>
        </a>
        <a href="/quiz/result/X" className="custom-button-link">
          <button className="custom-button">24년 10주차</button>
        </a>
      </div>
    </div>
  );
};

export default Ques5;