import { initializeApp } from 'firebase/app';
import { getFirestore } from 'firebase/firestore'; // Firestore import
import { getStorage } from 'firebase/storage'; // Storage import

// Your Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyAvcgcn8KCn8KCn8KCn6RZz_n-eU2rgYpjOpxsxBg",
  authDomain: "event-aaa09.firebaseapp.com",
  projectId: "event-aaa09",
  storageBucket: "event-aaa09.appspot.com",
  messagingSenderId: "561180316239",
  appId: "1:561180316239:web:db2c9f065ae7e3a736d03a",
  measurementId: "G-3CPBVDZP77"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const db = getFirestore(app); // Firestore 초기화
const storage = getStorage(app); // Firebase Storage 초기화

export { db, storage }; // db 및 storage 내보내기