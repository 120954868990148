import React from 'react';
import { useNavigate } from 'react-router-dom';
import '../../../assets/OX.css'; // O와 X 스타일 공유 가능
import nekoO from '../../../assets/nekoO.png'; // 이미지 import

const O = () => {
  const navigate = useNavigate();

  const handleReturnToQuiz = () => {
    navigate('/quiz'); // 퀴즈 탭으로 돌아가기
  };

  return (
    <div className="result-container">
      <img src={nekoO} alt="정답 이미지" className="result-image" /> {/* 이미지 추가 */}
      <p className="result-description">다른 문제도 맞춰봐.
      총 6문제나 있다고!</p>
      <button className="return-button" onClick={handleReturnToQuiz}>
        돌아가기
      </button>
    </div>
  );
};

export default O;