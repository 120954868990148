import '../assets/BucketList.css';

export default function BucketList() {
  return (
    <div className="bucket-list-wrapper">
      <div className="bucket-list-container">
        <h1>내 버켓리스트</h1>
        <ul>
          <li>1. CS 공부</li>
          <li>2. PS 공부</li>
          <li>3. 영어 공부</li>
          <li>4. 운동하기</li>
          <li>5. 매일 기록하기</li>
        </ul>
      </div>
      <div className="bucket-list-container">
        <h1>누나의 버킷리스트</h1>
        <ul>
          <li>1. 건강 챙기기</li>
          <li>2. 아르바이트 하기</li>
          <li>3. 3D 실력 기르기</li>
          <li>4. 취미생활하기</li>
          <li>5. 진로탐색하기</li>
        </ul>
      </div>
    </div>
  );
}